import { m, useInView } from "framer-motion";
import { useRef } from "react";


const About = () => {
    const conRef = useRef(null)
    const isInView = useInView(conRef)

    
    return <>
        <section ref={conRef} id="about" className="w-full mt-[150px] mb-[120px] max-w-[1600px] flex justify-center relative">  
            <div className="max-w-[900px] px-5 flex items-center justify-center text-center">
                    <m.div
                        className="flex flex-col"
                        initial={{scale: 2, opacity: 0}}
                        animate={isInView ? {scale: 1, opacity: 1} : {}}
                        transition={{ duration: 0.6, type: "spring" }}
                    >
                        <div className="flex items-center justify-center gap-4 max-lg:gap-3 max-md:flex-col max-md:gap-0">
                            <h2 className="whitespace-nowrap text-[5rem] max-xl:text-[4rem] max-lg:text-[3rem] max-md:text-[2rem] max-md:leading-[20px]">What is</h2>
                            <img className="max-w-[400px] max-xl:max-w-[300px] max-lg:max-w-[250px]" src="/assets/Logo Asset 1.png" alt="" />
                        </div>
                        
                        <div className="text-[1.3rem] text-white mt-2 max-md:mt-6">
                            <p>CoinSafe is the next generation of MFA designed to protect your digital assets. By leveraging blockchain technology, we eliminate traditional vulnerabilities like phishing and SIM swaps, while offering a seamless user experience.</p>
                        </div>
                        <div className="mt-6 text-[1.1rem]">
                            <a className="underline" href="https://docs.coinsafe.ai" >Learn More About Our Technology</a>
                        </div>
                    </m.div>
                {/*<div style={{background: 'radial-gradient(rgba(0, 179, 255, 0.4) 0px, transparent 60%)'}} className="absolute w-[1300px] h-[1300px] rounded-full top-[-350px] left-[280px] max-xl:left-[230px] max-lg:left-[-150px] max-md:left-[-250px] max-lg:top-[-400px] pointer-events-none z-[-1]"></div>*/}
            </div>

        </section>
    </>
}

export default About