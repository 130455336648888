import { m, useInView } from "framer-motion";
import { useRef } from "react";


const StepByStep = () => {
    const conRef = useRef(null)
    const isInView = useInView(conRef)

    
    return <>
        <section id="stepbystep" className="my-[100px] max-w-[1600px]">  
            <div ref={conRef} className="flex flex-col">
                <div className="w-full flex items-center justify-center text-center">
                    <m.div
                        initial={{scale: 2, opacity: 0}}
                        animate={isInView ? {scale: 1, opacity: 1} : {}}
                        transition={{ duration: 0.6, type: "spring" }}
                    >
                        <h2 className="whitespace-nowrap text-[4rem] max-xl:text-[3.2rem] max-lg:text-[3rem] max-md:text-[2rem]">How CoinSafe Works</h2>
                        <h1>Step-by-Step Process</h1>
                    </m.div>
                </div>
                <div className="mt-12 w-full">
                    <div className="flex flex-wrap w-fit m-auto max-lg:flex-col gap-x-10">
                        <div className="py-[30px] px-[10px] max-w-[280px] max-lg:max-w-3xl max-lg:m-auto text-[1.2rem]">
                            <div className="w-fit m-auto relative" >
                                <div className="z-[999] flex items-center justify-center h-[100px] w-[100px] p-[20px] bg-trasparent ring-2 ring-gray-800 text-gold-300 rounded-[50%] m-auto">
                                    <span className="text-[2.1rem]" >01</span>
                                </div>
                            </div>
                            <p className="text-center mt-6 text-black-100"><span className="text-gold-300" >Initiate Login or Transaction:</span> The user begins a login or transaction on a platform integrated with CoinSafe.</p>
                        </div>

                        <div className="py-[30px] px-[10px] max-w-[280px] max-lg:max-w-3xl max-lg:m-auto text-[1.2rem]">
                            <div className="w-fit m-auto relative" >
                                <div className="z-[999] flex items-center justify-center h-[100px] w-[100px] p-[20px] bg-trasparent ring-2 ring-gray-800 text-gold-300 rounded-[50%] m-auto">
                                    <span className="text-[2.1rem]" >02</span>
                                </div>
                            </div>
                            <p className="text-center mt-6 text-black-100"><span className="text-gold-300" >Approve with One Tap:</span> The request is verified on the Sui blockchain, then sent to the user’s CoinSafe app. The user simply approves the request with a single tap, secured by cryptographic signatures.</p>
                        </div>

                        <div className="py-[30px] px-[10px] max-w-[280px] max-lg:max-w-3xl max-lg:m-auto text-[1.2rem]">
                            <div className="w-fit m-auto relative" >
                                <div className="z-[999] flex items-center justify-center h-[100px] w-[100px] p-[20px] bg-trasparent ring-2 ring-gray-800 text-gold-300 rounded-[50%] m-auto">
                                    <span className="text-[2.1rem]" >03</span>
                                </div>
                            </div>
                            <p className="text-center mt-6 text-black-100"><span className="text-gold-300" >Final Blockchain Verification:</span> Once approved, the Sui blockchain finalizes the transaction, ensuring full security, transparency, and protection against attacks.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default StepByStep