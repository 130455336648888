import { m, useInView } from "framer-motion";
import { useRef } from "react";



const Roadmap = () => {
    const conRef = useRef(null)
    const isInView = useInView(conRef)

    
    return <>
        <section id="roadmap" className="mt-[100px] pt-10 max-w-[1600px] relative">  
            <div ref={conRef} className="flex flex-col">
                <div className="w-full flex px-2">
                    <m.div
                        initial={{scale: 2, opacity: 0}}
                        animate={isInView ? {scale: 1, opacity: 1} : {}}
                        transition={{ duration: 0.6, type: "spring" }}
                    >
                        <h2 className="text-[4rem] max-xl:text-[3.2rem] max-xl:text-[3rem] max-md:text-[2rem]">Roadmap</h2>
                        <h1 className="max-w-[800px]" >Our journey to revolutionize digital security, from MVP development to global adoption. See how we’re building the future of secure, blockchain-powered authentication.</h1>
                    </m.div>
                </div>
                <div className="font-semibold text-white justify-around flex w-full mt-4 xl:mt-16 max-md:px-[1rem] mb-20 max-xl:flex-col px-2 max-xl:px-24 overflow-hidden gap-5" >
                    <div className="flex-1 bg-gold-300 rounded-t-[20px] max-xl:rounded-b-[20px] flex flex-col justify-between">
                        <div className="p-5">
                            <div className="text-[1.2rem] mb-2" >2024</div>
                            <p className="text-[1.2rem] mb-2" >MVP development, smart contracts, CoinSafe API, and app</p>
                            <p className="text-white text-[1.2rem] leading-[24px]">
                                Internal testing and security audits.
                            </p>
                        </div>
                        <div className="px-5 py-1 bg-white rounded-t-lg border-t border-black max-xl:rounded-b-[20px]">
                            <span className="text-[2rem] text-gold-300 font-bold" >Q4</span>
                        </div>
                    </div>
                    <div className="flex-1 bg-gold-300 rounded-t-[20px] flex flex-col justify-between max-xl:rounded-b-[20px]">
                        <div className="p-5">
                            <div className="text-[1.2rem] mb-2" >2025</div>
                            <p className="text-[1.2rem] mb-2" >Roll out beta testing with select platforms and users.</p>
                            <p className="text-white text-[1.2rem] leading-[24px]">
                                Refine the product based on user feedback and testing results.
                            </p>
                        </div>
                        <div className="px-5 py-1 bg-white rounded-t-lg border-t border-black max-xl:rounded-b-[20px]">
                            <span className="text-[2rem] text-gold-300 font-bold" >Q1</span>
                        </div>
                    </div>
                    <div className="flex-1 bg-gold-300 rounded-t-[20px] flex flex-col justify-between max-xl:rounded-b-[20px]">
                        <div className="p-5">
                            <div className="text-[1.2rem] mb-2" >2025</div>
                            <p className="text-[1.2rem] mb-2" >Official product launch with full marketing campaign.</p>
                            <p className="text-white text-[1.2rem] leading-[24px]">
                                Begin onboarding additional platforms and expanding user base.
                            </p>
                        </div>
                        <div className="px-5 py-1 bg-white rounded-t-lg border-t border-black max-xl:rounded-b-[20px]">
                            <span className="text-[2rem] text-gold-300 font-bold" >Q2</span>
                        </div>
                    </div>
                    <div className="flex-1 bg-gold-300 rounded-t-[20px] flex flex-col justify-between max-xl:rounded-b-[20px]">
                        <div className="p-5">
                            <div className="text-[1.2rem] mb-2" >2025</div>
                            <p className="text-[1.2rem] mb-2" >Expand globally and target new industries (fintech, banking, e-commerce).</p>
                            <p className="text-white text-[1.2rem] leading-[24px]">
                                Develop enterprise solutions and advanced features.
                            </p>
                        </div>
                        <div className="px-5 py-1 bg-white rounded-t-lg border-t border-black max-xl:rounded-b-[20px]">
                            <span className="text-[2rem] text-gold-300 font-bold" >Q3</span>
                        </div>
                    </div>
                </div>
                {/*<div className="text-white flex w-full mt-4 xl:mt-16 mb-20 flex-col xl:flex-col px-6 xl:px-24 overflow-hidden" >
                    <div className="flex w-full flex-col xl:flex-row tableBg">
                        <div className="flex flex-col w-full">
                            <div className="w-full p-2 xl:p-6 border-[#00c5ff] border-[1px] relative">
                                <h1 className="uppercase text-black">Q1 2025 – Beta Testing & Early Partnerships</h1>
                            </div>
                            <div className="h-48 w-full p-6 border-[#00c5ff] border-[1px]">
                                <ul className="text-gray-800">
                                    <li>Beta Launch</li>
                                    <li>User Feedback</li>
                                    <li>Platform Integrations</li>
                                    <li>Security Audits</li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-col w-full">
                            <div className="w-full p-2 xl:p-6 border-[#00c5ff] border-[1px] relative">
                                <h1 className="uppercase text-black">Q2 2025 – Full Product Launch</h1>
                            </div>
                            <div className="h-48 w-full p-6 border-[#00c5ff] border-[1px]">
                                <ul className="text-gray-800">
                                    <li>Public Launch</li>
                                    <li>Marketing Campaign</li>
                                    <li>API Access for Developers</li>
                                    <li>App Release</li>
                                    <li>Expand Team</li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-col w-full">
                            <div className="w-full p-2 xl:p-6 border-[#00c5ff] border-[1px] relative">
                                <h1 className="uppercase text-black">Q3-Q4 2025 – Scaling and Strategic Partnerships</h1>
                            </div>
                            <div className="h-48 w-full p-6 border-[#00c5ff] border-[1px]">
                                <ul className="text-gray-800">
                                    <li>Onboard Major Platforms</li>
                                    <li>Partnership Expansion</li>
                                    <li>International Expansion</li>
                                    <li>New Features Development</li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-col w-full">
                            <div className="w-full p-2 xl:p-6 border-[#00c5ff] border-[1px] relative">
                                <h1 className="uppercase text-black">2026 – Advanced Features & Growth</h1>
                            </div>
                            <div className="h-48 w-full p-6 border-[#00c5ff] border-[1px]">
                                <ul className="text-gray-800">
                                    <li>Enterprise Solution</li>
                                    <li>Blockchain Partnerships</li>
                                    <li>Explore Additional Use Cases</li>
                                    <li>Monetization Strategy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
            <div style={{ background: 'radial-gradient(rgb(244 162 48 / 22%) 0,transparent 60%)'}} className="absolute w-[1600px] h-[1600px] rounded-full bottom-[0] left-[-400px] max-lg:left-[-500px] max-md:left-[-600px] pointer-events-none z-[-1]"></div>

        </section>
    </>
}

export default Roadmap